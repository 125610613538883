<template>
  <div class="useral-edit-refunds" :class="[{'vx-card': $route.name === 'editRefund'}]">
    <template v-for="i in 5">
      <div class="input-field flex">
        <label class="input-label w:1/2">
          {{ refundsHoursInputs[i - 1].time }} :
        </label>
        <div class="input-percent">
            <span class="percent-icon">
              %
            </span>
          <custom-validate-input class="number-style my-3"
                                 :key="i"
                                 invalid-text=" "
                                 :regex="percentRegex"
                                 :not-valid="handleInputValid(i - 1)"
                                 v-model="refundsHoursInputs[i - 1].refund"
                                 @input="handleInputValue(i - 1, $event)"/>
        </div>
        <div v-if="!refundsHoursInputs[i - 1].refund.isValid" class="in-valid">
          {{ handleInputValid(i - 1) ? $t('refunds.validators.value') : ''}}
          {{ !handleInputValid(i - 1) ? $t('refunds.validators.percentRange') : ''}}
        </div>
      </div>

    </template>

    <vs-button v-show="false"
               id="editRefundBTN"
               @click="sendData"/>
  </div>
</template>

<script>
  import axios from 'axios'
  import CustomValidateInput from '@/components/customInput/customValidateInput'
  import {checkUserPermissions} from '@/assets/js/functions'
  import {editRefund, getRefund} from "../../../../../http/requests/club/refundRules";

  export default {
    name: 'EditRefund',
    components: {
      CustomValidateInput
    },
    props: {
      refundId: {
        type: [Number, String],
        default: 0
      }
    },
    data() {
      return {
        percentRegex: this.$validator('regex.refund.percent'),
        nameRegex: this.$validator('regex.refund.name'),
        limits: [
          {
            id: 0,
            label: this.$t('refunds.timeLabels.all')
          },
          {
            id: 1,
            label: this.$t('refunds.timeLabels.all')
          }
        ],
        refunds: {
          id: 0,
          limit: {
            id: -1
          },
          name: '',
          price: '',
          character: ''
        },
        refundTypes: {
          1: {
            name: this.$t('refunds.list.actions.invoice'),
            event: 'invoice',
            icon: 'INVOICE'
          },
          2: {
            name: this.$t('refunds.list.actions.saleAddon'),
            event: 'addon',
            icon: 'PLUS'
          },
          3: {
            name: this.$t('refunds.list.actions.coach'),
            event: 'coach',
            icon: 'USER'
          }
        },
        hoursList: [],
        refundsHoursValues: [
          {
            label: this.$t('refunds.timeLabels.all'),
            value: {
              id: 0,
              initialId: 0,
              name: this.$t('refunds.timeLabels.all'),
              value: 0
            }
          },
          {
            label: this.$t('refunds.timeLabels.fromTo', {from: '24', to: '12'}),
            value: {
              id: 2,
              initialId: 0,
              name: this.$t('refunds.timeLabels.fromTo', {from: '24', to: '12'}),
              value: 12
            }
          },
          {
            label: this.$t('refunds.timeLabels.fromTo', {from: '48', to: '24'}),
            value: {
              id: 3,
              initialId: 0,
              name: this.$t('refunds.timeLabels.fromTo', {from: '48', to: '24'}),
              value: 24
            }
          },
          {
            label: this.$t('refunds.timeLabels.fromTo', {from: '48', to: '12'}),
            value: {
              id: 4,
              initialId: 0,
              name: this.$t('refunds.timeLabels.fromTo', {from: '48', to: '12'}),
              value: 48
            }
          },
          {
            label: this.$t('refunds.timeLabels.to', {time: '72'}),
            value: {
              id: 5,
              initialId: 0,
              name: this.$t('refunds.timeLabels.to', {time: '72'}),
              value: 72
            }
          },
        ],
        refundsHoursInputs: [
          {
            id: 0,
            initialId: 0,
            time: this.$t('refunds.timeLabels.from', {time: '12'}),
            hour: 0,
            refund: {
              value: '',
              isValid: true
            }
          },
          {
            id: 1,
            initialId: 0,
            time: this.$t('refunds.timeLabels.fromTo', {from: '24', to: '12'}),
            hour: 12,
            refund: {
              value: '',
              isValid: true
            }
          },
          {
            id: 2,
            initialId: 0,
            time: this.$t('refunds.timeLabels.fromTo', {from: '48', to: '24'}),
            hour: 24,
            refund: {
              value: '',
              isValid: true
            }
          },
          {
            id: 3,
            initialId: 0,
            time: this.$t('refunds.timeLabels.fromTo', {from: '72', to: '48'}),
            hour: 48,
            refund: {
              value: '',
              isValid: true
            }
          },
          {
            id: 4,
            initialId: 0,
            time: this.$t('refunds.timeLabels.to', {time: '72'}),
            hour: 72,
            refund: {
              value: '',
              isValid: true
            }
          },
        ],
        actions: {
          toolbar: [
            {
              id: 'editRefundBTN',
              icon: 'SAVE',
              iconPack: 'useral',
              color: 'success',
              permission: 'refund_rule.update'
            }
          ],
          leftToolbar: [
            {
              id: 'routeBackBTN',
              route: {name: 'refundProfile', params: {id: this.$route.params.id}},
              icon: 'CHEVRON_LEFT',
              iconPack: 'useral'
            }
          ]
        }
      }
    },
    created() {
      if (this.$route.name === 'refunds') {
        this.actions.leftToolbar.splice(0, 1)
      }
      setTimeout(() => {
        this.$store.dispatch('updateNavbarActions', this.actions)
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      }, 50)
      this.getRefund()
    },
    methods: {
      getRefund () {
        getRefund(this.refundId || this.$route.params.id).then(response => {
          const refunds = response.data.data

          this.refunds.id = refunds.id
          const orderedHours = refunds.schedules.map(e => e.hour).sort((a,b) => a - b)
          const orderSchedules = refunds.schedules.sort((a,b) => a.hour - b.hour)
          if (refunds.schedules.length > 0) {
            if (refunds.schedules.length > 1) {
              this.refunds.limit.id = 1
            } else {
              this.refunds.limit.id = 0
            }
            orderSchedules.forEach(item => {
              const hourIndex = this.refundsHoursValues.map(e => e.value.value).indexOf(item.hour)
              const hourInputIndex = this.refundsHoursInputs.map(e => e.hour).indexOf(item.hour)
              this.hoursList.push({
                id: this.refundsHoursValues[hourIndex].value.id || 0,
                initialId: item.id,
                hour: item.hour || 0,
                refund: {
                  value: `${item.percent}`,
                  isValid: true
                }
              })
              setTimeout(() => {
                this.refundsHoursInputs[hourInputIndex].refund.value = `${item.percent}`
                this.refundsHoursInputs[hourInputIndex].initialId = item.id
                this.handleInputValue(hourInputIndex, this.refundsHoursInputs[hourInputIndex].refund)
              }, 50)

              if (hourIndex > -1) {
                this.refundsHoursValues.splice(hourIndex, 1)
              }
            })
            this.$store.dispatch('setPageTitle', this.$t('refunds.edit.dynamicTitle', {name: this.refundTypes[refunds.type].name}))
          }
        })
      },
      handleInputValue (index, val) {
        for (let i = index; i < 5; i++) {
          this.refundsHoursInputs[i].refund.value = val.value
        }
        // this.$forceUpdate()
      },
      handleInputValid(index) {
        if (index > 0) {
          let minimumCount = 0
          for (let i = 0; i < index; i++) {
            if (parseInt(this.refundsHoursInputs[index].refund.value) < parseInt(this.refundsHoursInputs[i].refund.value)) {
              minimumCount++
            }
          }
          if (minimumCount > 0) {
            return true
          }
          return parseInt(this.refundsHoursInputs[index].refund.value) < parseInt(this.refundsHoursInputs[index - 1].refund.value)
        } else return false
      },
      checkUserPermissions(permission) {
        return checkUserPermissions(permission)
      },
      sendData() {
        let errorNum = 0
        let schedules = []
        this.refundsHoursInputs.forEach(item => {
          if (item.refund.isValid) {
            schedules.push({
              id: item.initialId > 0 ? item.initialId : null,
              hour: item.hour,
              percent: parseInt(item.refund.value)
            })
          } else {
            this.$vs.notify({
              title: this.$t('alert.error.title'),
              text: this.$t('refunds.notifications.wrongValue', {name: item.time}),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'danger'
            })
            errorNum++
            return false
          }
        })

        const refundHours = {
          schedules: schedules
        }


        if (errorNum === 0) {
          editRefund(this.refundId || this.$route.params.id, refundHours).then(() => {
            this.$vs.notify({
              title: this.$t('alert.message.title'),
              text: this.$t('refunds.notifications.edit.success'),
              icon: 'icon-check',
              iconPack: 'feather',
              time: 2400,
              color: 'success'
            })
            if (this.$route.name === 'editRefund') {
              this.$router.back()
            }
            this.$emit('edited')
          }).catch((error) => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            } else {
              const error_mapper = {
                'name': '',
              }

              switch (error.response.status) {
                case 422:
                  Object.keys(error.response.data.errors).forEach((error_key) => {
                    const err = error_key.toString().split('.')
                    if (error_mapper[err[err.length - 1]]) {
                      this.$vs.notify({
                        title: this.$t('alert.error.title'),
                        text: error_mapper[err[err.length - 1]],
                        color: 'danger',
                        icon: 'icon-alert-circle',
                        iconPack: 'feather',
                        time: 2400
                      })
                      error_mapper[err[err.length - 1]] = null
                    }
                  })
                  break

                default:
                  this.$vs.notify({
                    title: this.$t('alert.error.title'),
                    text: this.$t('refunds.notifications.edit.error'),
                    color: 'danger',
                    icon: 'icon-alert-circle',
                    iconPack: 'feather',
                    time: 2400
                  })
                  break
              }
            }
          })
        }
      }
    }
  }
</script>

<style lang="scss">
  @import "../../../../../assets/scss/vuexy/variables";
  .useral-edit-refunds {
    width: 100%;

    .input-field {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 85%;
      margin: auto;

      .input-percent {
        display: flex;
        align-items: center;
        justify-content: left;

        .percent-icon {
          font-weight: bold;
          font-size: 18px;
        }

        .number-style {
          width: 40px;
          /*margin: 15px 0;*/
          margin-left: 10px;
        }
      }

      .in-valid {
        position: absolute;
        bottom: 0;
        color: $danger;
        font-size: 11px;
        margin: auto;
        text-align: center;
      }
    }
  }
</style>
